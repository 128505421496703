/* eslint-disable no-irregular-whitespace */
import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.list {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-size: 16px;
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const InvestorCharter = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      {/* <h1 style={{ marginBottom: 15 }}>Investor Charter</h1> */}

      <div className="inner">
        <StyledText>
          <div
            id="market-Intelligence-subscription"
            style={{
              borderBottom: '1px solid rgb(188, 197, 207)',
              paddingBottom: '20px',
              paddingTop: '30px',
            }}>
            <h3> Market Intelligence Subscription – Your Edge in Trading & Investing</h3>
            <p style={{ fontSize: '16px' }}>
              <strong>Stockizen Research – SEBI Registered Research Analyst (INH000017675)</strong>
            </p>
            <h3> What is Market Intelligence?</h3>
            <p style={{ fontSize: '16px' }}>
              Market Intelligence is a <strong>research-driven market insights service</strong>{' '}
              designed for traders and investors looking to stay ahead with{' '}
              <strong>data-backed market trends, sentiment analysis, and key updates</strong>.
            </p>
            <p style={{ fontSize: '16px' }}>
              It provides a <strong>structured approach to understanding market movements</strong>{' '}
              with deep insights into
              <strong>
                global & domestic factors, FII trends, smart money moves, and technical levels
              </strong>
              .
            </p>
            <h3> What You Get</h3>
            <ul className="list">
              {[
                `Daily Global & Domestic Market Insights (Stay ahead with key market-moving updates.)`,
                `Daily Market Pulse – Sentiment Review (Understand market positioning & investor sentiment before making decisions.)`,
                `Daily Nifty & Bank Nifty View with Key Levels (Identify key price levels for structured trading decisions.)`,
                `Trading Strategies & Screeners (Curated setups & tools to refine your trades – not stock tips.)`,
                `Exclusive Learning Content for Trading & Investing (Deep dive into technicals, fundamentals & smart money tracking.)`,
                `Weekly Market Outlook (A structured roadmap for the upcoming week’s trading opportunities.)`,
                `FII + Promoters Updates (Track institutional & smart money flow in markets.)`,
                `Smart Investors + Big Fund Portfolio Updates (Follow the moves of top investors & funds.)`,
              ].map((item, index) => (
                <li key={index}>{parse(item)}</li>
              ))}
            </ul>
            <h3> Subscription Details</h3>
            <p style={{ fontSize: '16px' }}>
               <strong>Monthly Plan:</strong> ₹250
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Quarterly Plan:</strong> ₹750
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Subscribe Now:</strong>{' '}
              <a href="https://stockizen.rpy.club/g/2aEhzBheZS" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>

            <h3> Disclaimer</h3>
            <p style={{ fontSize: '16px' }}>
              Investment in securities market is subject to market risks. Read all related documents
              carefully before subscribing.
            </p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              <a href="/disclaimer" target="_blank">
                Read Full Disclaimer
              </a>{' '}
              | {' '}
              <a href="/refund-policy" target="_blank">
                {' '}
                Refund Policy
              </a>
            </p>
          </div>

          <div
            id="wealth-x"
            style={{
              borderBottom: '1px solid rgb(188, 197, 207)',
              paddingBottom: '20px',
              paddingTop: '30px',
            }}>
            <h3> Wealth X Value Investing Subscription</h3>
            <p style={{ fontSize: '16px' }}>
              <strong>Stockizen Research – SEBI Registered Research Analyst (INH000017675)</strong>
            </p>
            <h3> What is Wealth X?</h3>
            <p style={{ fontSize: '16px' }}>
              Wealth X is a <strong>research-based value investing subscription</strong> designed
              for investors who seek <strong> long-term investment insights</strong>. Our approach
              integrates <strong>fundamental and technical research (Techno+Funda)</strong> to
              provide data-driven stock analysis.
            </p>
            <h3> What You Get</h3>
            <ul className="list">
              {[
                `1-2 Stock Ideas Per Month (Equity investing, no intraday or momentum trading)`,
                `Techno+Funda Research Reports (Comprehensive analysis covering fundamentals, technicals, and market trends)`,
                `Market Insights & Trend Analysis (Macroeconomic trends, sector outlook, and stock-specific research)`,
                `Weekly Market Review (Nifty, Bank Nifty, Midcap & Small Cap updates)`,
              ].map((item, index) => (
                <li key={index}>{parse(item)}</li>
              ))}
            </ul>
            <h3> Subscription Details</h3>
            <p style={{ fontSize: '16px' }}>
               <strong>Quarterly Plan:</strong> ₹7500
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Minimum Capital Requirement:</strong> ₹5 Lakhs
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>SEBI Registered Research Analyst:</strong> INH000017675
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>BSE Enlistment Number:</strong> BSE/RA/25072024/6309
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Subscribe Now:</strong>{' '}
              <a href="https://stockizen.rpy.club/g/5BqOVvUpR7" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>

            <h3> Disclaimer</h3>
            <p style={{ fontSize: '16px' }}>
              Investment in securities market is subject to market risks. Read all related documents
              carefully before investing.
            </p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              <a href="/disclaimer" target="_blank">
                Read Full Disclaimer
              </a>{' '}
              | {' '}
              <a href="/refund-policy" target="_blank">
                {' '}
                Refund Policy
              </a>
            </p>
          </div>

          <div
            id="momentum-x"
            style={{
              borderBottom: '1px solid rgb(188, 197, 207)',
              paddingBottom: '20px',
              paddingTop: '30px',
            }}>
            <h3> Momentum X Membership</h3>
            <p style={{ fontSize: '16px' }}>
              <strong>Stockizen Research – SEBI Registered Research Analyst (INH000017675)</strong>
            </p>
            <h3> What is Momentum X?</h3>
            <p style={{ fontSize: '16px' }}>
              Momentum X is a <strong>research-driven insights service</strong> designed for traders
              looking to analyze
              <strong>short-term trading opportunities using structured research</strong>. It
              focuses on
              <strong>Techno+Funda-based research reports</strong> to support well-informed market
              decisions.
            </p>
            <h3> What You Get</h3>
            <ul className="list">
              {[
                `5-7 Stock Ideas Per Month (Short-term opportunities – No intraday calls)`,
                `Techno+Funda Research Reports (In-depth stock analysis combining price action, market structure, and fundamental factors)`,
                `Daily Global & Domestic Market Insights (Key updates on FII/DII flows, macroeconomic trends, and market sentiment)`,
                `Weekly Market Review (Covering Nifty, Bank Nifty, Midcap & Small Cap movements)`,
              ].map((item, index) => (
                <li key={index}>{parse(item)}</li>
              ))}
            </ul>
            <h3> Subscription Details</h3>
            <p style={{ fontSize: '16px' }}>
               <strong>Quarterly Plan:</strong> ₹9000
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Minimum Capital Requirement:</strong> ₹3 Lakhs
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>SEBI Registered Research Analyst:</strong> INH000017675
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>BSE Enlistment Number:</strong> BSE/RA/25072024/6309
            </p>
            <p style={{ fontSize: '16px' }}>
               <strong>Subscribe Now:</strong>{' '}
              <a href="https://stockizen.rpy.club/g/YF8Lugrb1C" target="_blank" rel="noreferrer">
                Click Here
              </a>
            </p>

            <h3> Disclaimer</h3>
            <p style={{ fontSize: '16px' }}>
              Investment in securities market is subject to market risks. Read all related documents
              carefully before investing.
            </p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              <a href="/disclaimer" target="_blank">
                Read Full Disclaimer
              </a>{' '}
              | {' '}
              <a href="/refund-policy" target="_blank">
                {' '}
                Refund Policy
              </a>
            </p>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default InvestorCharter;
