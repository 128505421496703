import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--black);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    // ${({ theme }) => theme.mixins.button};
    margin-top: 30px;
    font-family: var(--font-sans);
    font-size: var(--fz-xxl);
    background-color: var(--green);
    color: white;
    padding: 10px 20px 4px 20px;
    border-radius: var(--border-radius);
    &:hover {
      background-color: white;
      border: 1px solid var(--green);
      border-radius: var(--border-radius);
      color: var(--green);
    }
  }

  .services-link {
    // ${({ theme }) => theme.mixins.button};
    // margin-top: 30px;
    font-family: var(--font-sans);
    font-size: var(--fz-xxl);
    background-color: black;
    color: white;
    padding: 10px 20px 4px 20px;
    border-radius: var(--border-radius);
    &:hover {
      background-color: white;
      border: 1px solid var(--green);
      border-radius: var(--border-radius);
      color: var(--green);
    }
  }

  @media (max-width: 768px) {
    .twitter-profile {
      margin-top: 30px;
    }
    .email-link {
      margin-top: 0px;
    }
    .mobile-services {
      flex-direction: column !important;
      text-align: center;
    }

    .big-heading {
      font-size: clamp(30px, 8vw, 60px);
      // margin-top: 200px
    }
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <></>;
  // <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Stockizen Research</h2>;
  const three = <></>;
  const four = (
    <>
      <h2
        style={{
          textAlign: 'center',
        }}>
        {/* Full Time Trader & MicroCap Investor */}
        SEBI Registered Research Analyst
        {/* <a href="https://upstatement.com/" target="_blank" rel="noreferrer">
          Upstatement
        </a> */}
      </h2>
      <p style={{ textAlign: 'center' }}>SEBI Registration Number - INH000017675</p>
      <p style={{ textAlign: 'center' }}>BSE Enlistment Number - BSE/RA/25072024/6309</p>
    </>
  );
  const five = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
      }}
      className="mobile-services twitter-profile">
      <a
        className="email-link"
        href="https://twitter.com/Stocki_zen"
        target="_blank"
        rel="noreferrer">
        Twitter Profile
      </a>
      <a className="email-link" href="/#contact" rel="noreferrer">
        Contact Us
      </a>
      <br></br>
    </div>
  );

  const six = (
    <div style={{ marginTop: 30 }}>
      <p
        style={{ fontSize: '30px', textAlign: 'center', margin: 0, fontWeight: 600 }}
        key={'services'}>
        Services
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
        }}
        className="mobile-services">
        <a className="services-link" href="/market-Intelligence-subscription" rel="noreferrer">
          Market Intelligence
        </a>
        <a className="services-link" href="/wealth-x" rel="noreferrer">
          Wealth X
        </a>
        <a className="services-link" href="/momentum-x" rel="noreferrer">
          Momentum X
        </a>
      </div>
    </div>
  );

  const items = [one, two, three, four, five, six];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
