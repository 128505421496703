/* eslint-disable no-irregular-whitespace */
import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
  .heading {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    .heading {
      font-size: 40px;
    }
  }
`;
const StyledText = styled.div`
  ul.list {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-size: 16px;
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const InvestorCharter = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      {/* <h1 style={{ marginBottom: 15 }}>Investor Charter</h1> */}

      <div className="inner">
        <StyledText>
          <div
            id="momentum-x"
            style={{
              borderBottom: '1px solid rgb(188, 197, 207)',
              paddingBottom: '20px',
              paddingTop: '30px',
            }}>
            <h1 className="heading"> Momentum X - Subscription</h1>
            <p style={{ fontSize: '16px' }}>
              <strong>Stockizen Research – SEBI Registered Research Analyst (INH000017675)</strong>
            </p>
            <br></br>
            <h3> What is Momentum X?</h3>
            <p style={{ fontSize: '16px' }}>
              Momentum X is a <strong>research-driven insights service</strong> designed for traders
              looking to analyze{' '}
              <strong>short-term trading opportunities using structured research</strong>. It
              focuses on <strong>Techno+Funda-based research reports</strong> to support
              well-informed market decisions.
            </p>
            <br></br>
            <h3> What You Get</h3>
            <ul className="list">
              {[
                `<strong>5-7 Stock Ideas Per Month</strong> (Short-term opportunities – No intraday calls)`,
                `<strong>Techno+Funda Research Reports</strong> (In-depth stock analysis combining price action, market structure, and fundamental factors)`,
                `<strong>Daily Global & Domestic Market Insights</strong> (Key updates on FII/DII flows, macroeconomic trends, and market sentiment)`,
                `<strong>Weekly Market Review </strong>(Covering Nifty, Bank Nifty, Midcap & Small Cap movements)`,
              ].map((item, index) => (
                <li key={index}>{parse(item)}</li>
              ))}
            </ul>
            <br></br>
            <h3> Subscription Details</h3>
            <p style={{ fontSize: '16px' }}>
              <strong>Yearly Plan:</strong> ₹24,000
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>Quarterly Plan:</strong> ₹10,000
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>Minimum Capital Requirement:</strong> ₹3 Lakhs
            </p>
            <p style={{ fontSize: '26px' }}>
              <strong>Subscribe Now:</strong>{' '}
              <a
                href="https://stockizen.rpy.club/g/YF8Lugrb1C"
                target="_blank"
                rel="noreferrer"
                className="button-link">
                Click Here
              </a>
            </p>
            <br></br>
            <p style={{ fontSize: '16px' }}>
              <strong>SEBI Registered Research Analyst:</strong> INH000017675
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>BSE Enlistment Number:</strong> BSE/RA/25072024/6309
            </p>

            <br></br>
            <h3> Disclaimer</h3>
            <p style={{ fontSize: '16px' }}>
              Investment in securities market is subject to market risks. Read all related documents
              carefully before investing.
            </p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              <a href="/disclaimer" target="_blank">
                Read Full Disclaimer
              </a>{' '}
              |{' '}
              <a href="/refund-policy" target="_blank">
                {' '}
                Refund Policy
              </a>
            </p>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default InvestorCharter;
