/* eslint-disable no-irregular-whitespace */
import React, { useRef } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledAboutSection = styled.section`
  @media (max-width: 480px) {
    padding: 121px 0px;
  }
  @media (max-width: 768px) {
    padding: 121px 0px;
  }

  .inner {
    display: grid;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .button-lin {
    // ${({ theme }) => theme.mixins.button};
    margin-top: 30px;
    font-family: var(--font-sans);
    font-size: var(--fz-xxl);
    background-color: var(--green);
    color: white;
    padding: 10px 20px 4px 20px;
    border-radius: var(--border-radius);
    &:hover {
      background-color: white;
      border: 1px solid var(--green);
      border-radius: var(--border-radius);
      color: var(--green);
    }
  }

  .heading {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    .heading {
      font-size: 40px;
    }
  }
`;
const StyledText = styled.div`
  ul.list {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-size: 16px;
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const InvestorCharter = () => {
  const revealContainer = useRef(null);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      {/* <h1 style={{ marginBottom: 15 }}>Investor Charter</h1> */}

      <div className="inner">
        <StyledText>
          <div
            id="market-Intelligence-subscription"
            style={{
              borderBottom: '1px solid rgb(188, 197, 207)',
              paddingBottom: '20px',
              paddingTop: '30px',
            }}>
            <h1 className="heading"> Market Intelligence Subscription</h1>
            <p style={{ fontSize: '16px' }}>
              <strong>Stockizen Research – SEBI Registered Research Analyst (INH000017675)</strong>
            </p>
            <br></br>
            <h3> What is Market Intelligence?</h3>
            <p style={{ fontSize: '16px' }}>
              Market Intelligence is a <strong>research-driven market insights service</strong>{' '}
              designed for traders and investors looking to stay ahead with{' '}
              <strong>data-backed market trends, sentiment analysis, and key updates</strong>.
            </p>
            <p style={{ fontSize: '16px' }}>
              It provides a <strong>structured approach to understanding market movements</strong>{' '}
              with deep insights into{' '}
              <strong>
                global & domestic factors, FII trends, smart money moves, and technical levels
              </strong>
              .
            </p>
            <br></br>
            <h3> What You Get</h3>
            <ul className="list">
              {[
                `<strong>Daily Global & Domestic Market Insights</strong> (Stay ahead with key market-moving updates.)`,
                `<strong>Daily Market Pulse – Sentiment Review</strong> (Understand market positioning & investor sentiment before making decisions.)`,
                `<strong>Daily Nifty & Bank Nifty View with Key Levels</strong> (Identify key price levels for structured trading decisions.)`,
                `<strong>Trading Strategies & Screeners</strong> (Curated setups & tools to refine your trades – not stock tips.)`,
                `<strong>Exclusive Learning Content for Trading & Investing</strong> (Deep dive into technicals, fundamentals & smart money tracking.)`,
                `<strong>Weekly Market Outlook</strong> (A structured roadmap for the upcoming week’s trading opportunities.)`,
                `<strong>FII + Promoters Updates</strong> (Track institutional & smart money flow in markets.)`,
                `<strong>Smart Investors + Big Fund Portfolio Updates</strong> (Follow the moves of top investors & funds.)`,
              ].map((item, index) => (
                <li key={index}>{parse(item)}</li>
              ))}
            </ul>
            <br></br>
            <h3> Subscription Details</h3>
            <p style={{ fontSize: '16px' }}>
              <strong>Yearly Plan:</strong> ₹4,000
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>Quarterly Plan:</strong> ₹1,000
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>Monthly Plan:</strong> ₹333
            </p>
            <p style={{ fontSize: '26px' }}>
              <strong>Subscribe Now:</strong>{' '}
              <a
                href="https://stockizen.rpy.club/g/2aEhzBheZS"
                target="_blank"
                rel="noreferrer"
                className="button-link">
                Click Here
              </a>
            </p>
            <br></br>

            <p style={{ fontSize: '16px' }}>
              <strong>SEBI Registered Research Analyst:</strong> INH000017675
            </p>
            <p style={{ fontSize: '16px' }}>
              <strong>BSE Enlistment Number:</strong> BSE/RA/25072024/6309
            </p>
            <br></br>
            <h3> Disclaimer</h3>
            <p style={{ fontSize: '16px' }}>
              Investment in securities market is subject to market risks. Read all related documents
              carefully before subscribing.
            </p>
            <p style={{ fontSize: '16px' }}>
              {' '}
              <a href="/disclaimer" target="_blank">
                Read Full Disclaimer
              </a>{' '}
              |{' '}
              <a href="/refund-policy" target="_blank">
                {' '}
                Refund Policy
              </a>
            </p>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default InvestorCharter;
